import React, { useRef, useEffect } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "./Header.css";
import { Image } from "react-bootstrap";
import headerImage from "../images/image0.jpg";
import { Facebook, Instagram } from "react-bootstrap-icons";

const Header = () => {
	const headerRef = useRef();

	useEffect(() => {
		const handleScroll = () => {
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			headerRef.current.style.transform = `translateY(${scrollTop * 0.78}px)`;

		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (

		<>
			<Navbar bg="light" expand="lg" className="header">
				<Navbar.Brand href="/">Aproxima</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="mr-auto">
						<NavDropdown title="Курсове" id="basic-nav-dropdown">
							<NavDropdown.Item href="/course/Mathematics">Математика</NavDropdown.Item>
							<NavDropdown.Item href="/course/Informatics">Информатика</NavDropdown.Item>
							<NavDropdown.Item href="/course/Languages">Езиково обучение</NavDropdown.Item>
						</NavDropdown>
						<Nav.Link href="/information">Информация и цени</Nav.Link>
						<Nav.Link href="/gallery">Галерия</Nav.Link>
						<Nav.Link href="/team">Екип</Nav.Link>
						<Nav.Link href="/contact">Свържете се с нас</Nav.Link>
						<NavDropdown.Divider />
						<Nav.Link target="_blank" href="https://www.facebook.com/profile.php?id=100092611184453"><center><Facebook /></center></Nav.Link>
						<Nav.Link target="_blank" href="https://www.instagram.com/school.aproxima.biz/"><center><Instagram /></center></Nav.Link>


					</Nav>
				</Navbar.Collapse>
			</Navbar>
			<div className="header-image-container" ref={headerRef}>
				<Image src={headerImage} fluid />
			</div>
		</>
	);


};

export default Header;
