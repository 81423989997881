import AproximaCarousel from "../components/Carousel";

const Page = () => (
	<div>
		<AproximaCarousel />
		<p style={{ marginTop: 33 }}>
			Добре дошли в Апроксима - частно училище по математика и информационни технологии! Ние сме мястото, където ученето е забавно и предизвикателно, а нашата мисия е да подготвим следващото поколение ученици за успешна кариера в областта на науката и технологиите.
		</p>
		<p>
			В Апроксима разбираме, че в съвременния свят науката и технологиите имат все по-голямо значение. Именно затова сме се посветили да предоставим качествено образование в областта на математиката и информационните технологии, като използваме най-съвременните методи и инструменти за обучение. Специално проектираната ни програма насърчава учениците да изграждат критично мислене и технически умения, необходими за успешната им кариера в бъдеще.
		</p>
		<p>
			Ние сме горди, че нашите ученици имат отлични резултати в националните и международните състезания по математика и информационни технологии. Но за нас успехът на учениците ни не се измерва само в оценки, тестове и състезания - ние насърчаваме тяхното любопитство и креативност, както и развитието на меки умения като лидерство и екипен дух.
		</p>
		<p>
			Ако търсите образование, което ще ви подготви за бъдещето, Апроксима е мястото за вас! Разгледайте нашата уеб страница, за да научите повече за нас и нашата образователна програма. Ако имате въпроси, не се колебайте да се свържете с нас - ще се радваме да ви помогнем да вземете най-доброто решение за вас или вашето дете.
		</p>
	</div>

);

export default Page;
