
import elza from "../images/elza.jpg";
import tsvetan from "../images/tsvetan.jfif";
import pavel from "../images/pavel.jfif";
// import empty from "../images/empty_profile.png";
import { Card } from "react-bootstrap";


const Page = () => (
	<div>
		<h2>ЕКИП</h2>
		<hr />
		<br />
		<br />
		<div style={{width: 'fit-content', margin: "0 auto", display: 'flex'}}>
			<Card style={{ width: 'min(40vw, 18rem)', margin: '1vw', display: 'inline-block' }}>
				<Card.Img variant="top" src={elza} />
				<Card.Body>
					<Card.Title>Елза Евтимова</Card.Title>
					<Card.Text>
					Управител на АПРОКСИМА ЕООД<br/>
					профил: математика и информатика
					</Card.Text>
					{/* <Button variant="primary">Go somewhere</Button> */}
				</Card.Body>
			</Card>
			</div><div style={{width: 'fit-content', margin: "0 auto", display: 'flex'}}>
			<Card style={{ width: 'min(40vw, 18rem)', margin: '1vw', display: 'inline-block' }}>
				<Card.Img variant="top" src={pavel} />
				<Card.Body>
					<Card.Title>Павел Цанков</Card.Title>
					<Card.Text>
						PhD математика; Софтуерен инженер<br/>
						профил: математика и софтуер
					</Card.Text>
					{/* <Button variant="primary">Go somewhere</Button> */}
				</Card.Body>
			</Card>
			<Card style={{ width: 'min(40vw, 18rem)', margin: '1vw', display: 'inline-block' }}>
				<Card.Img variant="top" src={tsvetan} />
				<Card.Body>
					<Card.Title>Цветан Цанков</Card.Title>
					<Card.Text>
						Софтуерен инженер<br/>
						профил: информатика и софтуер
					</Card.Text>
					{/* <Button variant="primary">Go somewhere</Button> */}
				</Card.Body>
			</Card>
		</div>
	</div>
);

export default Page;
