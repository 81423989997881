import { AlignCenter } from "react-bootstrap-icons";
import Image12 from "../images/image12.png";
import { Accordion } from "react-bootstrap"

const renderParagraph = (str, index = 0) => <p key={index}>{index + 1}. {str}</p>
const masiv4 = [
	"Числата до 100. Събиране и изваждане.",
	"Умножение и деление с едноцифрено число. Текстови задачи.",
	"Геометрични фигури. Измерване.",
	"Естествени числа.",
	"Окръжност. Римски цифри.",
	"Събиране и изваждане на естествените числа без преминаване.",
	"Намиране на неизвестен умалител, неизвестно умаляемо.",
	"Измерване и чертаене на ъгъл. Геометрични задачи.",
	"Събиране на естествените числа с преминаване.",
	"Изваждане на естествените числа с преминаване.",
	"Куб. Правоъгълен паралелепипед.",
	"Преговор.",
	"Умножение на многоцифрено число с едноцифрено число.",
	"Умножение на естествените числа с едноцифрено число.",
	"Математическо кенгуру.",
	"Лице на правоъгълник.",
	"Деление на многоцифрено число с едноцифрено.",
	"Деление на естествените числа с едноцифрено число.",
	"Намиране на неизвестно делимо, неизвестен делител.",
	"Преговор.",
	"Умножение на многоцифрени числа с двуцифрено число.",
	"По-сложни задачи върху умножение на многоцифрени числа с двуцифрено число.",
	"Деление на многоцифрени числа с двуцифрено число.",
	"По-сложни задачи върху деление на многоцифрени числа с двуцифрено число.",
	"Деление с двуцифрено число на числата след 1000.",
	"Решаване на по-сложни задачи.",
	"Преговор.",
	"Самостоятелно решаване на текстови задачи.",
	"Естествените числа. Събиране и изваждане.",
	"Умножение и деление с едноцифрено и двуцифрено число.",
	"Сравняване. Текстови задачи.",
	"Геометрични задачи.",
	"Преговор.",
	"Текстови задачи."
]
const masiv5 = [
	"Деление с остатък.",
	"Кратно и делител на естествено число.",
	"Делимост на сбор и на произведение.",
	"Признаци за делимост на 2, на 5 и на 10.",
	"Признаци за делимост на 3 и на 9.",
	"Представяне на естествени числа като произведение на прости множители.",
	"Общ делител и НОД на естествени числа. Намиране на НОД на естествени числа.",
	"Общо кратно и НОК на естествени числа. Намиране на НОК на естествени числа.",
	"Обикновени дроби.",
	"Правилни и неправилни дроби.",
	"Основно свойство на дробите.  Разширяване на дроби.",
	"Основно свойство на дробите. Съкращаване на дроби.",
	"Привеждане на обикновени дроби към общ/най-малък общ знаменател.",
	"Сравняване и изобразяване на обикновени дроби върху числов лъч.",
	"Събиране на обикновени дроби с равни знаменатели.",
	"Изваждане на обикновени дроби с равни знаменатели.",
	"Смесени числа. Преминаване от смесено число в неправилна дроб и обратно.",
	"Събиране на обикновени дроби с различни знаменатели.",
	"Изваждане на обикновени дроби с различни знаменатели.",
	"Разместително и съдружително свойство на действието събиране.",
	"Събиране и изваждане на обикновени дроби. Намиране на неизвестно събираемо, умалител и умаляемо.",
	"Умножение на обикновени дроби.",
	"Разместително и съдружително свойство на действието умножение.",
	"Деление на обикновени дроби.",
	"Действия с обикновени дроби. Разпределително свойство на умножението относно събирането.",
	"Действия с обикновени дроби. Намиране на неизвестен множител, делимо и делител.",
	"Част от число. Основни задачи.",
	"Десетични дроби. Четене и записване на десетични дроби.", 
	"Сравняване и изобразяване на десетични дроби.", 
	"Закръгляване. Оценка на резултат.", 
	"Събиране на десетични дроби.", 
	"Разместително и съдружително свойство на действието събиране.", 
	"Изваждане на десетични дроби.", 
	"Събиране и изваждане на десетични дроби. Намиране на неизвестно събираемо, умалител и умаляемо.",
	"Умножение на десетични дроби с естествено число.",
	"Умножение на десетични дроби.",
	"Разместително и съдружително свойство на действието умножение.",
	"Деление на десетична дроб с естествено число.",
	"Умножение и деление на десетична дроб с 10, 100, 1000 и т.н. Преминаване от една мерна единица в друга.",
	"Деление на десетична дроб с десетична дроб.",
	"Действия с десетични дроби. Разпределително свойство на умножението относно събирането.",
	"Действия с десетични дроби. Намиране на неизвестен множител, делимо и делител.",
	"Използване на калкулатор. Приложение.",
	"Превръщане на десетични дроби в обикновени и на обикновени дроби в десетични.",
	"Процент. Основни задачи.",
	"Проста лихва.",
	"Четене и интерпретиране на данни. Работа с таблици.", 
	"Представяне на данни. Работа с диаграми. Основни геометрични фигури.",
	"Основни геометрични фигури.",
	"Перпендикулярни прави. Разстояние от точка до права.",
	"Триъгълник. Видове триъгълници. Елементи.",
	"Лице на равнинна фигура.",
	"Лице на правоъгълен триъгълник.",
	"Лице на триъгълник.",
	"Успоредни прави. Успоредник, ромб.",
	"Лице на успоредник.",
	"Трапец. Видове трапеци. Обиколка на трапец.",
	"Лице на трапец.",
	"Лица на геометрични фигури, съставени от изучените фигури.",
	"Куб. Елементи, развивка.",
	"Лице на повърхнина на куб.",
	"Обем на куб. Мерни единици за обем.",
	"Правоъгълен паралелепипед. Елементи, развивка.",
	"Лице на околна повърхнина и на повърхнина на правоъгълен паралелепипед.",
	"Обем на правоъгълен паралелепипед."
]
const masiv6 = [
	"Геометрични фигури и тела.",
	"Окръжност. Дължина на окръжност.",
	"Кръг. Лице на кръг.",
	"Многоъгълник. Правилен многоъгълник.",
	"Лице на многоъгълник.",
	"Призма. Правилна призма.",
	"Лице на повърхнина на права призма.",
	"Обем на права призма.",
	"Пирамида. Правилна пирамида.",
	"Лице на повърхнина на правилна пирамида.",
	"Обем на правилна пирамида.",
	"Прав кръгов цилиндър.",
	"Лице на повърхнина на прав кръгов цилиндър.",
	"Обем на прав кръгов цилиндър.",
	"Прав кръгов конус.",
	"Лице на повърхнина на прав кръгов конус.",
	"Обем на прав кръгов конус.",
	"Сфера. Лице на повърхнина на сфера.",
	"Кълбо. Обем на кълбо.",
	"Практически задачи от ръбести и валчести тела.",
	"Рационални числа.",
	"Положителни и отрицателни числа.",
	"Множество на рационалните числа.",
	"Изобразяване на рационалните числа върху числовата ос.",
	"Противоположни числа. Абсолютна стойност (модул) на рационално число.",
	"Сравняване на рационални числа.",
	"Събиране на рационални числа с еднакви знаци.",
	"Събиране на рационални числа с различни знаци.",
	"Свойства на събирането.",
	"Изваждане на рационални числа.",
	"Събиране и изваждане на рационални  числа. Разкриване на скоби.",
	"Алгебричен сбор. ",
	"Намиране на неизвестно събираемо.",
	"Умножение на рационални числа.",
	"Свойства на умножението.",
	"Деление на рационални числа. Свойства.",
	"Намиране на неизвестен множител.",
	"Декартова координатна система. Координати на точка.",
	"Построяване на симетрични точки на дадена точка спрямо началото и осите на координатна систем.",
	"Степенуване.",
	"Действие степенуване с естествен степенен показател.",
	"Числови изрази, съдържащи степени.",
	"Намиране на неизвестни компоненти при действие степенуване.",
	"Умножение на степени с равни основи.",
	"Деление на степени с равни основи.",
	"Намиране числена стойност на изрази, съдържащи степени.",
	"Степенуване на произведение.",
	"Степенуване на частно.",
	"Степенуване на степен.",
	"Степенуване на рационални числа.",
	"Степен с нулев показател и степен с цял показател.",
	"Стандартен запис на число.",
	"Питагоровата теорема – приложение на Степените.",
	"Числови равенства. Свойства.",
	"Уравнение от вида ax+b=0, където a може да е +=≠ 0.",
	"Правила за решаване на уравнение.",
	"Моделиране с уравнения от вида ax+b=0, където a може да е +=≠0 .",
	"Отношение. Пропорция.",
	"Пропорционалност. Коефициент на пропорционалност.",
	"Основно свойство на пропорциите.",
	"Свойства на пропорциите.",
	"Приложение на пропорциите.",
	"Права пропорционалност.",
	"Права пропорционалност – графика.",
	"Обратна пропорционалност – графика.",
	"Разчитане на данни, представени чрез диаграми и графики.",
	"Множества и операции с тях. Графично представяне на множества.",
	"Случайно събитие.",
	"Вероятност на случайно събитие като отношение на възможности.",
	"Описание на данни – средноаритметично.",
	"Организиране и представяне на данни."
]
const masiv7 = [
	"Естествени числа. Кратни и делители на число. Прости и съставни числа. Признаци за делимост.",
	"Рационални числа. Действия с рационални числа. Свойства на числови равенства и неравенства. Процент – основни задачи.",
	"Цели изрази. Тъждествени изрази. Формули за съкратено умножение.",
	"Разлагане на многочлени на множители.",
	"Линейни уравнения с едно неизвестно и уравнения, свеждащи се до линейно чрез еквивалентни преобразувания.",
	"Модулно линейно уравнение.",
	"Линейни неравенства с едно неизвестно и свеждащи се към тях чрез еквивалентни преобразувания.",
	"Преговор.",
	"Лице и периметър на равнинни фигури.",
	"Многоъгълник. Правилен многоъгълник.",
	"Правоъгълна координатна система. Разстояние от точка до права.",
	"Елементи и свойства на ръбести тела (куб, правоъгълен паралелепипед, права призма, правилна пирамида).",
	"Елементи и свойства на валчести тела (прав кръгов цилиндър, прав 2 кръгов конус, сфера и кълбо).",
	"Съседни и противоположни ъгли. Перпендикулярни прави.",
	"Успоредни прави – признаци и свойства.",
	"Триъгълник. Сбор от ъглите в триъгълник. Външен ъгъл на триъгълник.",
	"Еднакви триъгълници. Питагорова теорема.",
	"Симетрала на отсечка и ъглополовяща на ъгъл.",
	"Равнобедрен триъгълник. Равностранен триъгълник.",
	"Правоъгълен триъгълник. Медиана към хипотенуза в правоъгълен триъгълник. Правоъгълен триъгълник с ъгъл 30 градуса.",
	"Неравенства между страни и ъгли в триъгълника. Неравенство на триъгълника.",
	"Успоредник. Видове успоредници – правоъгълник, ромб, квадрат.",
	"Преговор.",
	"Представяне, разчитане и интерпретиране на данни, представени чрез диаграми и графики.",
	"Множества и операции с тях.",
	"Случайно събитие. Вероятност на случайно събитие.",
	"Преговор.",
	"Отношения и пропорции. Права и обратна пропорционалност;",
	"Средноаритметично на две и повече числа;",
	"Моделиране с изрази, линейни уравнения и неравенства.",
	"Преговор.",
	"Текстови задачи.",
]
const masiv8 = [
	"Умножение и събиране на възможности.",
	"Пермутации, вариации и комбинации.",
	"Вектор.",
	"Събиране и изваждане на вектори. Свойства.",
	"Умножение на вектор с число. Свойства.",
	"Делене на отсечка в дадено отношение.",
	"Средна отсечка в триъгълник.",
	"Медицентър на триъгълник.",
	"Трапец. Равнобедрен трапец.",
	"Средна отсечка (основа) на трапец.",
	"Ирационални числа. Квадратен корен.",
	"Свойства на квадратните корени.",
	"Действия с квадратни корени.",
	"Сравняване на ирационални числа, записани с квадратни корени.",
	"Преобразуване на изрази, съдържащи квадратни корени.",
	"Рационализиране на изрази, съдържащи квадратни корени.",
	"Квадратно уравнение. Непълни квадратни уравнения.",
	"Формула за корените на квадратното уравнение.",
	"Съкратена формула за корените на квадратното уравнение.",
	"Разлагане на квадратния тричлен на множители.",
	"Биквадратно уравнение.",
	"Уравнения от по-висока степен, свеждащи се до квадратни.",
	"Зависимости между корените и коефициентите на квадратното уравнение. Формули на Виет.",
	"Приложение на формулите на Виет.",
	"Моделиране с квадратни уравнения.",
	"Окръжност. Взаимни положения на точка и окръжност.",
	"Взаимни положения на права и окръжност.",
	"Допирателни към окръжност.",
	"Централни ъгли, дъги и хорди.",
	"Диаметър, перпендикулярен на хорда.",
	"Вписан ъгъл.",
	"Периферен ъгъл.",
	"Ъгъл, чийто връх е вътрешна точка за окръжност.",
	"Ъгъл, чийто връх е външна точка за окръжност.",
	"Взаимно положение на две окръжности.",
	"Общи допирателни на две окръжности.",
	"Рационални дроби. Дефиниционно множество.",
	"Основно свойство на рационалните дроби. Съкращаване и разширяване на рационални дроби.",
	"Привеждане на рационалните дроби към общ знаменател.",
	"Събиране и изваждане на рационални дроби.",
	"Умножение, деление и степенуване на рационални дроби.",
	"Преобразуване на рационални изрази.",
	"Дробни уравнения.",
	"Моделиране с дробни уравнения.",
	"Вписани и описани многоъгълници.",
	"Окръжност, описана около триъгълник.",
	"Окръжност, вписана в триъгълник.",
	"Външновписани окръжности.",
	"Ортоцентър на триъгълник. Забележителни точки в триъгълника.",
	"Четириъгълник, вписан в окръжност.",
	"Четириъгълник, описан около окръжност.",
	"Осева симетрия.",
	"Ротация.",
	"Централна симетрия.",
	"Транслация."
]
const masiv9 = [
	"Класическа вероятност.",
	"Вероятност на сума на несъвместими събития.",
	"Вероятност на противоположно събитие, на обединение и сечение на събития.",
	"Вероятност на сума на съвместими събития.",
	"Функция, дефиниционно множество. Начини на задаване на функции.",
	"Графика на линейната функция. Свойства. ",
	"Квадратна функция. Графика на функцията y = ax^2 .",
	"Графика на квадратната функция y = ax^2  + bx + c. Растене и намаляване на квадратна функция, най-малка и най-голяма стойност на квадратна функция.",
	"Графично представяне на решенията на уравнение.", 
	"Линейни уравнения с две неизвестни.",
	"Системи линейни уравнения с две неизвестни. Решаване чрез заместване.",
	"Взаимно разположение на графики на линейни функции. Изследване броя на решенията на система линейни уравнения.",
	"Решаване на системи линейни уравнения чрез събиране.",
	"Графично представяне на решенията на системи линейни уравнения с две неизвестни.",
	"Моделиране със системи линейни уравнения.",
	"Системи уравнения от втора степен с две неизвестни.",
	"Системи уравнения от втора степен с две неизвестни. Решаване на системи, на които едното уравнение е от първа степен.",
	"Системи уравнения с две неизвестни, на които двете уравнения са от втора степен.",
	"Моделиране със системи уравнения от втора степен с две неизвестни.",
	"Пропорционални отсечки.", 
	"Теорема на Талес. Обратна теорема на Талес.", 
	"Свойство на ъглополовящите в триъгълник.", 
	"Подобни триъгълници. Първи признак за подобност на триъгълници.", 
	"Втори и трети признак за подобност на триъгълници.", 
	"Свойства на подобните триъгълници.", 
	"Отношение на лицата на подобните триъгълници.",
	"Обединение и сечение на числови интервали. Неравенство от вида |ax+ b|>c.",
	"Системи линейни неравенства с едно неизвестно. Двойно неравенство. Неравенство от вида |ax+b|<c.",
	"Неравенства от вида (ax+b)(cx+d)>0, (ax+b)/(cx+d)>0.",
	"Квадратни неравенства. Метод на интервалите.", 
	"Приложение на метода на интервалите при решаване на неравенства от по- висока степен.", 
	"Дробни неравенства.", 
	"Метрични зависимости между отсечки в правоъгълен триъгълник.", 
	"Теорема на Питагор.", 
	"Намиране дължина на отсечка в правоъгълна координатна система.", 
	"Решаване на правоъгълен триъгълник.", 
	"Решаване на равнобедрен триъгълник.", 
	"Решаване на равнобедрен и правоъгълен трапец.", 
	"Решаване на успоредник.", 
	"Метрични зависимости между отсечки в окръжност.", 
	"Тригонометрични функции на остър ъгъл.",
	"Стойности на тригонометрични функции на ъгли с мерки 30°, 45°, 60°.",
	"Основни зависимости между тригонометричните функции на един и същ ъгъл.",
	"Тригонометрични функции на остри ъгли, които се допълват до 90°.",
	"Намиране на основните елементи на правоъгълен триъгълник.",
	"Намиране елементи на равнобедрен триъгълник.",
	"Намиране елементи на равнобедрен и правоъгълен трапец.",
	"Приложение на тригонометрични функции на остър ъгъл.",	
]
const masiv10 = [
	"Системи уравнения.",
	"Подобни триъгълници.",
	"Рационални неравенства.",
	"Метрични зависимости.",
	"Тригонометрични функции.",
	"Преговор.",
	"Ирационални изрази.",
	"Преобразуване на ирационални изрази.",
	"Ирационални уравнения с един квадратен радикал.",
	"Ирационални уравнения с два квадратни радикала.",
	"Решаване на ирационални уравнения чрез полагане.",
	"Преговор.",
	"Числови редици.",
	"Аритметична прогресия.",
	"Свойства на аритметичната прогресия.",
	"Сбор на първите n члена на аритметична прогресия.",
	"Геометрична прогресия.",
	"Свойства на геометричната прогресия.",
	"Сбор на първите n члена на геометрична прогресия.",
	"Комбинирани задачи от аритметична и геометрична прогресия.",
	"Проста лихва. Сложна лихва.",
	"Практически задачи, свързани с лихви.",
	"Преговор.",
	"Описателна статистика.",
	"Централни тенденции – средноаритметично, медиана и мода.",
	"Квартили и петчислeно представяне на данни.",
	"Тригонометричните функции синус, косинус, тангенс и котангенс в интервала [0°; 180°].",
	"Основни тригонометрични тъждества в интервала [0°; 180°]",
	"Таблица за стойностите на тригонометричните функции от някои специални ъгли в интервала [0°; 180°].",
	"Синусова теорема.",
	"Решаване на произволен триъгълник с помощта на синусова теорема. Основни задачи.",
	"Косинусова теорема",
	"Решаване на произволен триъгълник с помощта на косинусова теорема. Основни задачи.",
	"Формули за медиани на триъгълник. Формули за ъглополовящи на триъгълник.",
	"Формули за лице на триъгълник.",
	"Преговор.",
	"Прави и равнини в пространството. Взаимно положение на две прави.",
	"Взаимно положение на права и равнина. Перпендикулярност на права и равнина.",
	"Ортогонално проектиране. Ъгъл между права и равнина.",
	"Взаимно положение на две равнини. Ъгъл между две равнини.",
	"Права призма.",
	"Пирамида.",
	"Прав кръгов цилиндър.",
	"Прав кръгов конус.",
	"Сфера и кълбо.",
	"Преговор.",
	"Текстови задачи.",
]
const masiv11 = [
	"Корен трети. Свойства.",
	"Корен n-ти. Свойства.",
	"Преобразуване на ирационални изрази.",
	"Графики на функциите  y = √x, y =x^2 и y = ∛x.",
	"Степен с рационален степенен показател. Свойства.", 
	"Преобразуване на изрази, съдържащи степен с рационален степенен показател.",
	"Показателна функция. Графика.",
	"Логаритъм. Основни свойства. Сравняване на логаритми, Графика на логаритмична функция.",
	"Логаритмуване на произведение, частно, степен и корен.",
	"Решаване на успоредник.",
	"Решаване на трапец.",
	"Решаване на четириъгълник.",
	"Обобщен ъгъл. Радиан. Тригонометрични функции на обобщен ъгъл.",
	"Основни тригонометрични тъждества.",
	"Четност, нечетност и периодичност на тригонометричните функции.",
	"Графики на функциите y = sin x , y = cos x, y = tg x и y = cotg x.",
	"Формули за синус и косинус от сбор и разлика на два ъгъла.",
	"Формули за тангенс и котангенс от сбор и разлика на два ъгъла.",
	"Формули за тригонометрични функции от удвоен ъгъл.",
	"Формули за сбор и произведение на тригонометрични функции. Решаване на правилен многоъгълник.",
	"Условна вероятност. Теорема за умножение на вероятностите. Независимост.",
	"Модели на многократни експерименти с два възможни изхода.",
	"Разпределения на вероятностите със сума.",
	"Геометрична вероятност върху правата като отношение на дължини на интервали.",
	"Геометрична вероятност в равнината като отношение на лица.",
]
const masiv12 = [
	"Групиране на данни.",
	"Хистограма и полигон.",
	"Таблица и графично представяне на акумулаторните честоти.",
	"Средна аритметична стойност. Преговор с допълнение.",
	"Характеристики на разсейването.",
	"Вероятност и статистическа честота.",
	"Оценяване на неизвестен дял в генерална съвкупност чрез извадки.",
	"Практически задачи. Упражнение.",
	"Преговор.",
	"Модулни уравнение от вида |ax2 + bx + c|= m.",
	"Основни показателни уравнения.",
	"Показателни уравнения, свеждащи се чрез полагане до квадратни уравнения.",
	"Основни логаритмични уравнения.",
	"Логаритмични уравнения, свеждащи се чрез полагане до квадратни.",
	"Решаване на уравнения от вида sin x = a.",
	"Решаване на уравнения от вида cos x = a.",
	"Решаване на основни тригонометрични уравнения tg x = a и cotg x = a.",
	"Тригонометрични уравнения, които се свеждат до квадратни.",
	"Приложение на тригонометрията за решаване на геометрични задачи.",
	"Обобщение на темата: Уравнения.",
	"Преговор.",
	"Модулни неравенства от вида |ax2 + bx + c| >(<)m.",
	"Ирационални неравенства от вида ax2 + bx + c < mx + n (f (x) < g(x)).",
	"Ирационални неравенства от вида ax2 + bx + c < mx + n (f (x) > g(x)).",
	"Основни показателни неравенства.",
	"Основни логаритмични неравенства.",
	"Преговор.",
	"Екстремални задачи.",
	"Линейна и квадратна функция. Най-голяма и най-малка стойност.",
	"Основни елементарни неравенства.",
	"Екстремални задачи в алгебрата.",
	"Екстремални задачи в планиметрията.",
	"Практически задачи за намиране на най-голяма и най-малка стойност на елементарни функции.",
	"Графични модели при решаване на екстремални задачи."]

const Page = () => (
	<div>
		<h2>КУРСОВЕ ПО МАТЕМАТИКА</h2>
		<hr />
		<div style={{ textAlign: "right" }} class="flex-video">
		<img src={Image12} width= "100" height= "100" />
		</div>
		<h4>При нас може да намерите богат избор от курсове по математика, а именно:</h4>
		<p>Курсове от 4'ти до 12'ти клас</p>

		<Accordion defaultActiveKey="0">
			<Accordion.Item eventKey="0">
				<Accordion.Header><b>УЧЕБНА ПРОГРАМА ЗА 4'ти КЛАС</b></Accordion.Header>
				<Accordion.Body>
					{masiv4.map(renderParagraph)}
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="1">
				<Accordion.Header><b>УЧЕБНА ПРОГРАМА ЗА 5'ти КЛАС</b></Accordion.Header>
				<Accordion.Body>
					{masiv5.map(renderParagraph)}
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="2">
				<Accordion.Header><b>УЧЕБНА ПРОГРАМА ЗА 6'ти КЛАС</b></Accordion.Header>
				<Accordion.Body>
					{masiv6.map(renderParagraph)}
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="3">
				<Accordion.Header><b>УЧЕБНА ПРОГРАМА ЗА 7'ми КЛАС</b></Accordion.Header>
				<Accordion.Body>
					{masiv7.map(renderParagraph)}
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="4">
				<Accordion.Header><b>УЧЕБНА ПРОГРАМА ЗА 8'ми КЛАС</b></Accordion.Header>
				<Accordion.Body>
					{masiv8.map(renderParagraph)}
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="5">
				<Accordion.Header><b>УЧЕБНА ПРОГРАМА ЗА 9'ти КЛАС</b></Accordion.Header>
				<Accordion.Body>
					{masiv9.map(renderParagraph)}
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="6">
				<Accordion.Header><b>УЧЕБНА ПРОГРАМА ЗА 10'ти КЛАС</b></Accordion.Header>
				<Accordion.Body>
					{masiv10.map(renderParagraph)}
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="7">
				<Accordion.Header><b>УЧЕБНА ПРОГРАМА ЗА 11'ти КЛАС</b></Accordion.Header>
				<Accordion.Body>
					{masiv11.map(renderParagraph)}
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="8">
				<Accordion.Header><b>УЧЕБНА ПРОГРАМА ЗА 12'ти КЛАС</b></Accordion.Header>
				<Accordion.Body>
					{masiv12.map(renderParagraph)}
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	</div>
);

export default Page;
