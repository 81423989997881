import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Info from "./pages/Info";
import AboutUs from "./pages/AboutUs";
import Course from "./pages/Course";
import Mathematics from "./pages/Mathematics";
import Informatics from "./pages/Informatics";
import Languages from "./pages/Languages";
import SocialMedia from "./pages/SocialMedia";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";

import PageContainer from "./components/PageContainer";

const App = () => {
	return (
		<Router>
			<Header />
			<PageContainer>
				<Switch>
					<Route exact path="/" component={Home} />
					<Route path="/information" component={Info} />
					<Route path="/team" component={AboutUs} />
					<Route exact path="/course" component={Course} />
					<Route path="/course/Mathematics" component={Mathematics} />
					<Route path="/course/Informatics" component={Informatics} />
					<Route path="/course/Languages" component={Languages} />
					<Route path="/social" component={SocialMedia} />
					<Route path="/gallery" component={Gallery} />
					<Route path="/contact" component={Contact} />
				</Switch>
			</PageContainer>
			<Footer />
		</Router>
	);
};

export default App;
