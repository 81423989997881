const Page = () => (
	<div>
		<br />
		<br />
		<h2>Информация и цени</h2>
		<hr />
		<br />
		<br />
		<p>При нас децата са на сигурно място и в сигурни ръце.</p>
		<p>Преподавателите ни са опитни и са едни от водещите в своята научна област. Те са с богат педагогически опит и умело водят образователната дейност, а също непосредствено контактуват с децата;</p>
		<p>Нашето предимство е работата в малки групи, даваща възможност за обучение, близко до индивидуалното, за диференциран подход и грижа към всяко дете по време на  учебния  процес, както от страна на преподавателя, така и активното участие от страна на родителя.</p>
		<p>Децата се развиват и израстват пред очите ни. Ние ги подкрепяме и се радваме на техния прогрес.</p>
		<p>Поставяме детето в центъра на образователния и възпитателен процес и превръщането му в активна страна на обучението. Подтикваме го към изследване и логическо мислене. Постоянно го поощряваме да дава най-доброто от себе си в обучителния процес. Организираме стимулираща среда, с подходящ интериор, както за обучителните, така и за затвърждаващите знания.</p>
		<br />
		<br />
		<hr></hr>
		<h2>Финансови условия и такси при записване в избраните курсове:</h2>
		<br />
		<p>Таксата за групово обучение за ученици от всички класове по математика, информатика, английски език</p>
		<p> * Групов курс - 300 лв. : 15 дневен - 30 учебни часа, по 2 учебни часа дневно - 1 учебен час е 50 мин. </p>
		<p>АПРОКСИМА ЕООД предлага присъствено индивидуално обучение за ученици от всички класове</p>
		<p> * Индивидуален курс - 500 лв. : 10 дни, по 90 мин. дневно </p>
		<br />
		<p>Предлагат се групови и индивидуални курсове по информатика и английски език за работещи хора.</p>
		<p> * Групов курс - 300 лв. : 15 дневен - 30 учебни часа, по 2 учебни часа дневно - 1 учебен час е 50 мин. </p>
		<p> * Индивидуален курс - 500 лв. : 10 дни, по 90 мин. дневно </p>
		<br />
		<p>Таксите за курсове се предплащат по банков път по сметката:</p>            
		<br />        
		<p>АПРОКСИМА ЕООД</p>
		<h5>Банка ДСК</h5>   <h5>IBAN: BG29STSA93000023180240</h5>   <h5>BIC: STSABGSF</h5>
		<br />
		<p>Като основание записвате курса, който ще се посещава и имената на лицето, което ще се обучава.</p>
		<br />
		<br />
		<p>Очакваме Ви да се включите в нашите курсове.</p>


	</div>
);

export default Page;
