import { AlignCenter } from "react-bootstrap-icons";
import Image13 from "../images/image13.png";
import { Accordion } from "react-bootstrap"

const renderParagraph = (str, index = 0) => <p key={index}>{} {str}</p>

const masiv4 = [
"Частните уроци по английски език се провеждат с помощта на съвременни методики и учебни системи. Целта ни е да удовлетворим максимално интересите и очакванията на нашите клиенти.",
"Обучение е индивидуалното и групово изпълнено с динамика и интерактивност и носи бързи и качествени резултати. В образователния процес са застъпени и се усвояват всички основни езикови правила и действия - Четене, Слушане, Разговор и Писане.Важен акцент е развиването на комуникативните умения.",
"В процеса на обучение се използват компютри, аудио, видео и др. технически средства.",
"Броят на часовете и честотата на уроците са съобразени с личните предпочитания и потребности на учащия се.",
]

		const Page = () => (
	<div>
		<h2>ЕЗИКОВО ОБУЧЕНИЕ</h2>
		<hr />
		<div style={{ textAlign: "right" }} class="flex-video">
		<img src={Image13} width= "100" height= "100" />
		</div>
		<h4>При нас може да намерите богат избор от курсове по английски език, а именно:</h4>
		<p></p>
		<Accordion defaultActiveKey="0">
			<Accordion.Item eventKey="0">
				<Accordion.Header><b>Уроци по английски език</b></Accordion.Header>
				<Accordion.Body>
					{masiv4.map(renderParagraph)}
					</Accordion.Body>
			</Accordion.Item>
		</Accordion>

	</div>
);

export default Page;



