import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState } from "react";
import { Alert } from 'react-bootstrap';
import { useEffect } from 'react';
import React, { Component } from "react";

import { theme } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
 
const verifyCallback = response => console.log(response);
const expiredCallback = () => {};
const recaptchaRef = React.createRef();

function onChange(value) {
  console.log('Captcha value:', value);
}

function App() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [result, setResult] = useState("");
    const [problem, setProblem] = useState("");


    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);
   
    const handleOnClick = e => {
   
    }

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    useEffect(() => {
        setMessage('')
        setName('')
        setEmail('')
    }, [result, problem])

    const sendData = (e) => {
        e.preventDefault();
        fetch(window.location.origin + '/contactUs.php', {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            // mode: "cors", // no-cors, *cors, same-origin
            // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: "same-origin", // include, *same-origin, omit
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ name, email, message }), // body data type must match "Content-Type" header
        }).then(r => {
            return r.json()
        }).then(r => {
            console.log(r);
            if (r?.sent === true)
                setResult("Изпратено")
            else
                setProblem(r?.sent)
        }).catch(err => {
            setProblem(JSON.stringify(err));
        });
    };


    return (
        <>
            <Form action="action_page.php">
                <Form.Group className="mb-3" controlId="formName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control value={name} onChange={handleNameChange} placeholder="Име" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control value={email} type="email" onChange={handleEmailChange} placeholder="Вашият имеил адрес - email@abv.bg" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formMessage">
                    <Form.Label>Message</Form.Label>
                    <Form.Control value={message} as="textarea" rows={3} onChange={handleMessageChange} placeholder="Собщение" />
                </Form.Group>
            </Form>

    <div>
    <form>
            <div id="container" >Captcha</div>
            <script src="https://www.google.com/recaptcha/enterprise.js?render=6Lf-F10mAAAAAN_o6Ywcy-GlX7xjxlNqNGJ_gy1L">
            </script>
  

            <ReCAPTCHA
             sitekey="6Lf-F10mAAAAAN_o6Ywcy-GlX7xjxlNqNGJ_gy1L"
             onChange={onChange}
             locale="zh-TW"
             size="visible"
            />

            <Button style={{width: '100%'}} disabled={email.length === 0 || /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false} onClick={sendData}>Прати</Button>
             {(result || problem) && <hr />}
             {result && <Alert variant='success'>{result}</Alert>}
             {problem && <Alert variant='warning'>{problem}</Alert>}
    </form>
    </div>
            
        </>
    );
}

export default App;