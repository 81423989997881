import Carousel from 'react-bootstrap/Carousel';


import image1 from "../images/image1.jpg";
import image2 from "../images/image2.jpg";
import image4 from "../images/image4.jpg";
import image5 from "../images/image5.jpg";
import image6 from "../images/image6.jpg";
import image7 from "../images/image7.jpg";
import image8 from "../images/image8.jpg";

const renderItem = (key, imgProps, headerText, paragraphText = null) => {
    return <Carousel.Item key={key} interval={3000}>
        <div style={{ display: "flex", alignItems: 'center', justifyContent: "center", height: '50vh' }}>
            <img
                className="d-block w-100 caroImg"
                alt={`Aproxima ${key}`}
                {...imgProps}
            />
        </div>
        <Carousel.Caption style={headerText ? { padding: 15, background: "#0000008a", borderRadius: 15 } : {}}>
            {headerText && <h4>{headerText}</h4>}
            {paragraphText && <p className="text">{paragraphText}</p>}
        </Carousel.Caption>
    </Carousel.Item>
}


const items = [
    { imgProps: { src: image1, alt: 'Image One' }, headerText: "Добре дошли в Aproxima", paragraphText: "Школата, която предлага качествено образование в областта на математиката и информационните технологии" },
    { imgProps: { src: image2, alt: 'Image 3' }, headerText: 'Кодиране за бъдещето', paragraphText: 'Hашата инициатива, която насърчава учениците да научат програмиране и да се запознаят с най-новите технологии.' },
    { imgProps: { src: image4, alt: 'Image 4' } },
    { imgProps: { src: image5, alt: 'Image 5' }, headerText: "Математическо студио", paragraphText: "Hашата специализирана група за ученици с високи математически способности, която предоставя разширено обучение и подготовка за национални и международни състезания." },
    { imgProps: { src: image6, alt: 'Image 6' } },
    { imgProps: { src: image7, alt: 'Image 7' }, headerText: "Лятна школа по математика и информационни технологии", paragraphText: "Hашата лятна програма, която предоставя интензивно обучение в областта на математиката и информационните технологии." },
    { imgProps: { src: image8, alt: 'Image 8',  } }
]

function AproximaCarousel() {
    return (
        <Carousel style={{ height: '50vh', background: "#000" }}>
            {items.map((data, index) => renderItem(index, data.imgProps, data.headerText, data.paragraphText))}
        </Carousel>
    );
}

export default AproximaCarousel;