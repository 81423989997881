import { AlignCenter } from "react-bootstrap-icons";
import Image11 from "../images/image11.gif";
import { Accordion } from "react-bootstrap"

const renderParagraph = (str, index = 0) => <p key={index}>{} {str}</p>

const masiv4 = [
	"Microsoft Word е текстообработваща програма част от Microsoft Office, която служи за създаване или редактиране на документи като писма, бележки, доклади и др. В документите може да бъдат вмъкнати графики, таблици, изображения и диаграми, а текстът да бъде проверен за правописни и граматически грешки. Документите ще можете да оформяте според вашето желание. Тук вие ще се научите да създавате бланки за писма, пликове и етикети . За ваше улеснение и ефективност, бързо и автоматично ще можете да вмъквате често използван текст или графика. Програмата е широко разпространена и се използва във всички сектори на икономиката. Материала ще бъде поднесен от мотивирани специалисти с дълъг опит в преподаването , чрез интересни и подходящи примери.",
	"За кого е предназначен курса по MS Word: Курсът е предназначен, както за абсолютно начинаещи, така и за тези, които имат начални познания и желаят да ги доразвият към едно професионално ниво."
]
const masiv7 = [
	"Microsoft Excel е програма за електронни таблици, която ви позволява ефикасно да обработвате и съхранявате списъци от данни, да извършвате изчисления, да създавате отчети и диаграми. С помощта на Microsoft Excel е възможно лесно и бързо да се въвежда информация, която след това да се променя, изтрива или добавя. Не е необходимо да се тревожите дали въвеждате данните правилно и подробно. Винаги ще имате възможност да ги редактирате по-късно. Програмата е широко разпространена и се използва в почти всички сектори на икономиката. Материала ще бъде поднесен от мотивирани специалисти с дълъг опит в преподаването , чрез интересни и подходящи примери.",
	"За кого е предназначен курса по MS Excel за начинаещи: Курсът е предназначен, както за абсолютно начинаещи, така и за тези, които имат начални познания и желаят да ги доразвият към едно професионално ниво."
]
const masiv10 = [
	"VBA (Visual Basic for Applications) е обектно-ориентиран език за програмиране, вграден в MS Office и се използва в приложните програми на MS Office. VBA намира изключително голямо приложение за автоматизиране на задачи, когато в ежедневната работа често се налага да се извършват повтарящи се рутинни действия, за които не се изискват кой знае какви умения, но трябва да се изпълнят с голяма прецизност. Например: изчистване на първоначалните данни и подреждането на данните в таблици, удобни за анализ, задаване на един и същ формат на повече работни листа, автоматично актуализиране на данни в обобщаващите таблици (pivot tables), създаване на потребителски функции със сложни формули, автоматично импортиране на данни към други приложения на MS Office.",
	"Цел на курса по Visual Basic: Ще се научите как да записвате и редактирате макроси и различни начини на тестване и изпълнение. Макросите са основен момент в разбирането и „четенето“ на инструкциите, които се пишат на програмния език VBA. Те ще ви помогнат да се запознаете и да усвоите синтаксиса на VBA, за да редактирате записаните макроси, както и в написването на първите самостоятелни процедури. След завършване на курса, участниците ще могат да създават самостоятелно процедури, които ще използват в ежедневната си работа.",	
	"За кого е предназначен курсът? Курсът е предназначен за всеки, който работи активно с MS Excel и иска да се усъвършенства, както и за тези, които искат да открият допълнителните възможностите на MS Excel. Не е необходимо да имате предварителен опит в програмирането."
]
const masiv12 = [
	"Microsoft PowerPoint е програма за изработване на презентации. Използва се от фирми, преподаватели, студенти и др. PowerPoint е най-използваната програма в света за презентации.",
	"Възможностите на програмата, ще ви позволят лесно да впечатлите аудиторията с интересна и атрактивна презентация, която задържа вниманието върху себе си. Новите функции позволяват, бързо да печелите вниманието на публиката в желаната от вас посока.",
	"По време на курса вие ще се научите да създавате професионални презентации, да вмъквате в тях таблици, графики, диаграми, анимации, музика, както и много други ефекти и хипервръзки."
]

		const Page = () => (
	<div>
		<h2>КУРСОВЕ ПО ИНФОРМАТИКА</h2>
		<hr />
		<div style={{ textAlign: "right" }} class="flex-video">
		<img src={Image11} width= "100" height= "100" />
		</div>
		<h4>При нас може да намерите богат избор от курсове по информатика, а именно:</h4>
		<p></p>
		<Accordion defaultActiveKey="0">
			<Accordion.Item eventKey="0">
				<Accordion.Header><b>Microsoft Word</b></Accordion.Header>
				<Accordion.Body>
					{masiv4.map(renderParagraph)}
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="1">
				<Accordion.Header><b>Microsoft Excel</b></Accordion.Header>
				<Accordion.Body>
					{masiv7.map(renderParagraph)}
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="2">
				<Accordion.Header><b>Microsoft Visual Basic за Excel</b></Accordion.Header>
				<Accordion.Body>
					{masiv10.map(renderParagraph)}
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="3">
				<Accordion.Header><b>Microsoft PowerPoint</b></Accordion.Header>
				<Accordion.Body>
					{masiv12.map(renderParagraph)}
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
		<br />
		<h4>Допълнителна информация:</h4>
		<br />
		<p>Ползване на личен или служебен лаптоп:</p>

		<p>За нуждите на обучението са осигурени компютри за всички курсисти. Тези от тях, които все пак искат да ползват свой собствен лаптоп, могат да го носят.</p>

		<p>Мащаб на групите:</p>

		<p>Обучението се провежда в малка група, ограничена до 6 участника, с цел много повече индивидуално внимание на лектора към курсистите по време на практическите упражнения.</p>

		<p>Издаване на Сертификат:</p>

		<p>В края на курса се държи тест, като на всеки справил се курсист се издава Сертификат.</p>


	</div>
);

export default Page;
