import React from "react";
import { Gallery } from "react-grid-gallery";
import { Image, Modal, Button } from "react-bootstrap";
import Image1 from "../images/zala1.jpg";
import Image2 from "../images/zala2.jpg";
import Image3 from "../images/zala3.jpg";
import Image4 from "../images/zala4.jpg";
import Image5 from "../images/broshura-2.jpg";
import Image6 from "../images/broshura-1.jpg";

function MyVerticallyCenteredModal(props) {
	return (
		<Modal
			{...props}
			show={props.show.length > 0}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Снимка</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Image src={props.show} fluid />
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={props.onHide}>Close</Button>
			</Modal.Footer>
		</Modal>
	);
}

const Page = (props) => {
	const [modalShow, setModalShow] = React.useState("");
	const images = [
		{   
			src: Image1,
			width: 660,
			height: 900,
			tags: [
				{ value: "учебна зала", title: "учебна" },
			],
			// isSelected: true,
			caption: "Апроксима (учебна зала)",
		},
		{
			src: Image2,
			width: 660,
			height: 900,
			alt: "Апроксима (учебна зала)",
		},
		{
			src: Image3,
			width: 660,
			height: 900,
		},
		{
			src: Image4,
			width: 660,
			height: 900,
		},
		{
			src: Image5,
			width: 660,
			height: 900,
		},
		{
			src: Image6,
			width: 660,
			height: 900,
		},
	];

	return (
		<>
			<Gallery images={images} onClick={(index, { src }) => setModalShow(src)} />
			<MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow("")} />
		</>
	);
};

export default Page;
