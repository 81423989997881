import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import ContactForm from "./ContactForm";


function GroupExample() {
	return (
		<CardGroup>
			<Card>
				<Card.Body>
					<Card.Title style={{ textAlign: "center" }}>Email/Адрес</Card.Title>
					<Card.Text>
						<hr />
						<br />
						<p>info@aproxima.com</p>
						<p>school@aproxima.com</p>
						<p>contact@aproxima.com</p>
						<br />
						<p>тел: 0885 222 710</p>
						<br />
						<h2>Адрес:</h2>
						<p>Силвър център, TO-42 ет.1</p>
						<p>бул. „Джавахарлал Неру“ 28</p>
						<p>1324 ж.к. Люлин 7, София</p><br />
						<br />
					</Card.Text>
				</Card.Body>
				<Card.Footer style={{ textAlign: "center" }}>
					<small className="text-muted">нашите данни за контакт</small>
				</Card.Footer>
			</Card>
			<Card>
				<Card.Body>
					<Card.Title style={{ textAlign: "center" }}>Местоположение</Card.Title>
					<Card.Text>
						<hr />
						<div>
							<div class="small-12 medium-8 columns">
								<div style={{ textAlign: "center" }} class="flex-video">
									<iframe title='Location' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2931.459059973298!2d23.2516481!3d42.7151735!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40aa9a8f7e1c29d1%3A0x9d2e157a61032172!2z0KHQuNC70LLRitGAINGG0LXQvdGC0YrRgCwg0LHRg9C7LiDigJ7QlNC20LDQstCw0YXQsNGA0LvQsNC7INCd0LXRgNGD4oCcIDI4LCAxMzI0INC2LtC6LiDQm9GO0LvQuNC9IC0g0KbQtdC90YLRitGALCDQodC-0YTQuNGP!5e0!3m2!1sbg!2sbg!4v1683368038884!5m2!1sbg!2sbg" width="500" height="400" style={{ border: 0, maxWidth: '100%' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
								</div>
							</div>
						</div>
					</Card.Text>
				</Card.Body>
				<Card.Footer style={{ textAlign: "center" }}>
					<small className="text-muted">карта</small>
				</Card.Footer>
			</Card>
			<Card>
				<Card.Body>
					<Card.Title style={{ textAlign: "center" }}>Контактна форма</Card.Title>
					<Card.Text>
						<hr />
						<div position absoluten width="700" height="300">
							<ContactForm />
						</div>
					</Card.Text>
				</Card.Body>
				<Card.Footer style={{ textAlign: "center" }}>
					<small className="text-muted">може да ни попитате...</small>
				</Card.Footer>
			</Card>
		</CardGroup>
	);
}

export default GroupExample;
