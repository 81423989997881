import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
	return (
		<Container fluid className="footer">
			<Row>
				<Col style={{ textAlign: "center" }}>
					&copy; {new Date().getFullYear()} Aproxima. Всички права запазени.
				</Col>
			</Row>
		</Container>
	);
};

export default Footer;
