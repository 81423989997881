const Page = () => (
	<div>
		<br />
		<br />
		<h2>СОЦИАЛНИ МЕДИИ</h2>
    <hr />
		<br />
		<br />
    {/* <img href="https://www.facebook.com/profile.php?id=100092611184453" src="facebook.png" width="30" height="30" alt="" title="facebook"/><a>   </a><a href="https://www.facebook.com/profile.php?id=100092203998041">facebook</a>
	<br />
	<br />
	<img href="https://www.instagram.com/school.aproxima.biz/" src="instagram.png" width="30" height="30" alt="" title="instagram"/><a>   </a><a href="https://www.instagram.com/school.aproxima.biz/">instagram</a>
	<br /> */}
	<br />
	</div>
  );
  
  export default Page;