import { Container } from "react-bootstrap";
import "./PageContainer.css";

const PageWrapper = ({ children }) => {
	return (
		<Container fluid className="pageWrapper">
			{children}
		</Container>
	);
};

export default PageWrapper;
